import { RespBody } from "@/apis/interface/Resp";
import { basemodel } from "./basemodel";
export class ApplicationConfigModel extends basemodel {
	constructor(data: any | null) {
		super(data);
		super.setDefaultFormModelName("ApplicationConfigModel");
	}
	id?: number
	key?: string
	value?: string
	/**
	 * 修改时间：默认为当前时间
	 */
	createTime?: Date
	/**
	 * 创建时间：默认为当前时间（自动更新）
	 */
	updateTime?: Date
}
// API URL： /api/v1/management/application-config
// 管理端-程序配置
export interface IApplicationConfig {
	// 获取 配置
	// GET("/{key}")
	appletConfigByKey(key: string): Promise<RespBody<any>>
	// 获取 配置
	// GET("/{id}")
	appletConfigById(id: number): Promise<RespBody<any>>
	// 保存 配置
	// POST
	updateOrSave(model: ApplicationConfigModel): Promise<RespBody<boolean>>
	// 删除 配置, 逗号分割id数组
	// DELETE("/{ids}")
	remove(ids: Array<number>): Promise<RespBody<number>>
}
