
import {
  ApplicationConfigModel,
  IApplicationConfig
} from "@/apis/interface/IApplicationConfig";
import { ElMessage } from "element-plus";
import { defineComponent, ref, inject, onMounted } from "vue";

export default defineComponent({
  name: "List",
  setup() {
    const apiApplicationConfig = inject(
      "apiApplicationConfig"
    ) as IApplicationConfig;

    let appletConfig = ref(null);

    onMounted(async () => {
      let data = await apiApplicationConfig.appletConfigByKey("applet-config");
      let json = data.object.valueJson as any;
      appletConfig.value = json;
    });

    let submit = async () => {
      let v = appletConfig.value as any;
      v.banner = v.banner.filter((item: any) => !!item.pic);

      let result = await apiApplicationConfig.updateOrSave(
        new ApplicationConfigModel({
          key: "applet-config",
          value: JSON.stringify(appletConfig.value)
        })
      );
      if (result.object) {
        ElMessage({
          type: "success",
          message: "保存成功"
        });
      } else {
        ElMessage({
          type: "error",
          message: "保存失败"
        });
      }
    };

    let removePoster = async (one: any) => {
      let v = appletConfig.value as any;
      v.banner = v.banner.filter((item: any) => item.pic !== one.pic);
    };

    let addNewPoster = async () => {
      let v = appletConfig.value as any;
      if (v && v.banner) {
        v.banner.push({
          pic: null,
          type: "",
          target: ""
        });
      }
    };

    let removeAdv = async (one: any) => {
      let v = appletConfig.value as any;
      v.adv = v.adv.filter((item: any) => item.advImage !== one.advImage);
    };

    let addNewAdv = async () => {
      let v = appletConfig.value as any;
      if (v && v.adv) {
        v.adv.push({
          advImage: null,
          advType: "",
          advTarget: ""
        });
      }
    };

    return {
      appletConfig,
      addNewPoster,
      removePoster,
      addNewAdv,
      removeAdv,
      submit
    };
  }
});
